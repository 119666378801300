<template>
  <div class="login-content">
    <div class="center">
      <img class="title-img" src="../../assets/static/images/login/title.png" alt="" />
      <div class="welcome">Welcome !</div>
      <div class="tips">欢迎登录艺大佬工作台</div>
      <input
        class="input"
        type="text"
        placeholder="手机号码"
        v-model="form.mobile"
      />
      <input
        class="input"
        type="password"
        placeholder="密码"
        v-model="form.password"
      />
      <div class="checkbox-div">
        <input
          class="checkbox"
          type="checkbox"
          name="vehicle"
          value="Bike"
        />记住密码
      </div>
      <div class="login" @click="login">登录</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {},
    };
  },
  methods: {
    login() {
      this.$api.user.login(this.form).then((res) => {
        this.$ku.vuex("isLogin", true);
        this.$ku.vuex("vuex_userinfo", res.data.userinfo);
        this.$router.push("/mobile");
      });
    },
  },
};
</script>

<style lang="less" scoped>
.login-content {
  width: 100%;
  height: 100vh;
  background: url("../../assets/static/images/login/tuopan.png") #03112b no-repeat;
  background-size: 100%;
  background-position-y: 100%;
  .center {
    box-sizing: border-box;
    width: 80%;
    height: 80vh;
    margin: 0 auto;
    position: relative;
    top: 10vw;
    background: url("../../assets/static/images/login/bg.png") no-repeat;
    background-size: 100% 100%;
    padding: 35px 42px;
    .title-img {
      width: 86px;
      margin-bottom: 4.5%;
    }

    .welcome {
      font-size: 26px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #03112b;
      line-height: 44px;
      text-align: left;
    }

    .tips {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #dbaf58;
      line-height: 20px;
      text-align: left;
      margin: 3% 0;
    }

    .input {
      width: 100%;
      background: none;
      border: none;
      border-bottom: 1px solid #fff;
      padding: 15px 0;
      font-size: 15px;
      color: #fff;
      margin: 5% 0;
      &:focus {
        outline: none;
      }
      &::-webkit-input-placeholder {
        color: #fff;
      }
    }
    .checkbox-div {
      text-align: left;
      color: #fff;
      display: flex;
      align-items: center;
      font-size: 13px;
      .checkbox {
        width: 14px;
        height: 14px;
        background: #ffffff;
        border: 1px solid #ffffff;
        border-radius: 3px 3px 3px 3px;
        margin-left: 0;
      }
    }

    .login {
      background: #002968;
      font-size: 17px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #ffffff;
      padding: 13px 0;
      width: 138px;
      margin: 0 auto;
      border-radius: 50px;
      box-shadow: 0px 0px 15px #fff;
      margin-top: 6.45vh;
    }
  }
}
</style>
